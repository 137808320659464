import "./Nav.css";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { GrLinkedinOption } from "react-icons/gr";
import { FaGithub } from "react-icons/fa";
import { FiMail } from "react-icons/fi";
import logo from "../img/favicon_io/favicon-mid.png";

function Nav(props) {
  const usePathname = () => {
    const location = useLocation();
    return location.pathname;
  };
  const currentLocation = usePathname();
  return (
    <nav className="nav-body">
      <div className="logo">
        <img src={logo} alt="Logo"></img>
        <div>lukasz@milcz.co.uk</div>
      </div>
      <div className="nav-icon-container">
        <a href="mailto:lukasz@milcz.co.uk" target="blank">
          <FiMail className="nav-mail-icon" />
        </a>
        <a
          href="https://www.linkedin.com/in/lukasz-milcz-4b8296157/"
          target="blank"
        >
          <GrLinkedinOption className="nav-li-icon" />
        </a>
        <a href="https://github.com/lukablasi" target="blank">
          <FaGithub className="nav-gh-icon" />
        </a>
      </div>
      <ul className="nav-container">
        <Link to="/">
          <li
            className={currentLocation === "/" ? "nav-active" : "nav-inactive"}
          >
            home
          </li>
        </Link>
        <Link to="/about">
          <li
            className={
              currentLocation === "/about" ? "nav-active" : "nav-inactive"
            }
          >
            about me
          </li>
        </Link>
        <Link to="/skills">
          <li
            className={
              currentLocation === "/skills" ? "nav-active" : "nav-inactive"
            }
          >
            skills
          </li>
        </Link>
        <Link to="/work">
          <li
            className={
              currentLocation === "/work" ? "nav-active" : "nav-inactive"
            }
          >
            portfolio
          </li>
        </Link>
      </ul>
    </nav>
  );
}

export default Nav;
