import "./Contact.css";
import { GrLinkedinOption } from "react-icons/gr";
import { FaGithub } from "react-icons/fa";
import { FiMail } from "react-icons/fi";

function Contact() {
  return (
    <section className="contact-body">
      <h2 className="contact-header">Contact Me</h2>
      <p> lukasz@milcz.co.uk</p>
      <div className="contact-icon-container">
        <a href="mailto:lukasz@milcz.co.uk" target="blank">
          <FiMail className="mail-icon" />
        </a>
        <a
          href="https://www.linkedin.com/in/lukasz-milcz-4b8296157/"
          target="_blank"
        >
          <GrLinkedinOption className="li-icon" />
        </a>
        <a href="https://github.com/lukablasi" target="_blank">
          <FaGithub className="gh-icon" />
        </a>
      </div>
    </section>
  );
}

export default Contact;
