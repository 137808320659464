import "./Skills.css";
import { AiFillHtml5 } from "react-icons/ai";
import {
  FaBootstrap,
  FaVuejs,
  FaReact,
  FaNodeJs,
  FaCss3Alt,
  FaSass,
  FaGitAlt,
  FaFigma,
} from "react-icons/fa";
import { TbBrandJavascript, TbApi } from "react-icons/tb";
import { GiNothingToSay } from "react-icons/gi";
import {
  SiRedux,
  SiPostgresql,
  SiMongodb,
  SiGoogleoptimize,
  SiGimp,
} from "react-icons/si";

function Skills() {
  return (
    <section className="skills-body">
      <h2 className="skills-header">Skills</h2>
      <div className="skills-tables-container">
        <div>
          <p className="code">milcz=# SELECT FrontEnd FROM Skills;</p>
          <table className="skills-table">
            <thead>
              <tr>
                <th className="code code-table">Category</th>
                <th className="code code-table">Name</th>
                <th className="code code-table">Icon</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="ltd">FrontEnd</td>
                <td>HTML</td>
                <td className="rtd">
                  <AiFillHtml5 className="icon" color="#DD4B25" />
                </td>
              </tr>
              <tr>
                <td className="ltd">FrontEnd</td>
                <td>CSS</td>
                <td className="rtd">
                  <FaCss3Alt className="icon" color="#254BDD" />
                </td>
              </tr>
              <tr>
                <td className="ltd">FrontEnd</td>
                <td>SASS</td>
                <td className="rtd">
                  <FaSass className="icon" color="#C76494" />
                </td>
              </tr>
              <tr>
                <td className="ltd">FrontEnd</td>
                <td>Bootstrap</td>
                <td className="rtd">
                  <FaBootstrap className="icon" color="#7910F2" />
                </td>
              </tr>
              <tr>
                <td className="ltd">FrontEnd</td>
                <td>JavaScript</td>
                <td className="rtd">
                  <TbBrandJavascript className="icon" color="#EFD81D" />
                </td>
              </tr>
              <tr>
                <td className="ltd">FrontEnd</td>
                <td>React</td>
                <td className="rtd">
                  <FaReact className="icon" color="#5ED3F3" />
                </td>
              </tr>
              <tr>
                <td className="ltd">FrontEnd</td>
                <td>Redux</td>
                <td className="rtd">
                  <SiRedux className="icon" color="#6F43B5" />
                </td>
              </tr>
              <tr>
                <td className="ltd">FrontEnd</td>
                <td>Vue</td>
                <td className="rtd">
                  <FaVuejs className="icon" color="#3FB27F" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <p className="code">milcz=# SELECT BackEnd FROM Skills;</p>
          <table className="skills-table">
            <thead>
              <tr>
                <th className="code code-table">Category</th>
                <th className="code code-table">Name</th>
                <th className="code code-table">Icon</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="ltd">BackEnd</td>
                <td>NodeJS</td>
                <td className="rtd">
                  <FaNodeJs className="icon" color="#72A960" />
                </td>
              </tr>
              <tr>
                <td className="ltd">BackEnd</td>
                <td>REST API</td>
                <td className="rtd">
                  <TbApi className="icon" color="#266897" />
                </td>
              </tr>
              <tr>
                <td className="ltd">BackEnd</td>
                <td>PostgreSQL</td>
                <td className="rtd">
                  <SiPostgresql className="icon" color="#31648C" />
                </td>
              </tr>
              <tr>
                <td className="ltd">BackEnd</td>
                <td>MongoDB</td>
                <td className="rtd">
                  <SiMongodb className="icon" color="#429543" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <p className="code">milcz=# SELECT Other FROM Skills;</p>
          <table className="skills-table">
            <thead>
              <tr>
                <th className="code code-table">Category</th>
                <th className="code code-table">Name</th>
                <th className="code code-table">Icon</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="ltd">Other</td>
                <td>Git</td>
                <td className="rtd">
                  <FaGitAlt className="icon" color="#E94E31" />
                </td>
              </tr>
              <tr>
                <td className="ltd">Other</td>
                <td>SEO</td>
                <td className="rtd">
                  <SiGoogleoptimize className="icon" color="#B366F6" />
                </td>
              </tr>
              <tr>
                <td className="ltd">Other</td>
                <td>Figma</td>
                <td className="rtd">
                  <FaFigma className="icon" color="#EA4C1D" />
                </td>
              </tr>
              <tr>
                <td className="ltd">Other</td>
                <td>GIMP</td>
                <td className="rtd">
                  <SiGimp className="icon" color="#564F46" />
                </td>
              </tr>
              <tr>
                <td className="ltd">Other</td>
                <td>English</td>
                <td className="rtd">
                  <GiNothingToSay className="icon" color="#012066" />
                </td>
              </tr>
              <tr>
                <td className="ltd">Other</td>
                <td>Polish</td>
                <td className="rtd">
                  <GiNothingToSay className="icon" color="#AC0B08" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
}

export default Skills;
