import "./About.css";

function About() {
  return (
    <section className="about-body">
      <span className="code code-p">&lt;h2&gt;</span>
      <h2>About Me</h2>
      <span className="code code-p">&lt;/h2&gt;</span>
      <br />
      <span className="code code-p">&lt;p&gt;</span>
      <p>
        I graduated from OpenClassrooms in 2021 with Web Developer associate's
        level diploma and in 2023 with bachelor's level diploma as a result of
        my 3-year studies during which I developed multiple skills while working
        on real life Front-End and Full-Stack projects.
      </p>
      <p>
        With a knowledge I obtained during my studies I was able to implement
        solutions based on web technologies that improved quality and
        productivity at my current work environment and are being used across UK
        network.
      </p>
      <p>
        Now I am looking for an opportunity to move my career to the next step
        and join a team where I could develop and maintain web applications.
      </p>
      <p>
        During my time off I enjoy listening and playing music - I play several
        music instruments including guitar, bass, piano, accordion. I am also a
        fan of philosophy and history.
      </p>
      <span className="code code-p">&lt;/p&gt;</span>
      <br />
      <span className="code code-body">&lt;/body&gt;</span>
      <br />
      <span className="code">&lt;/html&gt;</span>
    </section>
  );
}

export default About;
