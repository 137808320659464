import "./Project.css";

function Project(props) {
  return (
    <div className="project-container">
      <div className="project-title">{props.title}</div>
      <div className="project-img-container">
        <img
          className="project-img project-img-one"
          alt={props.pic[0].alt}
          src={props.pic[0].path}
        ></img>
        <img
          className="project-img project-img-two"
          alt={props.pic[1].alt}
          src={props.pic[1].path}
        ></img>
        {
          <img
            className="project-img project-img-three"
            alt={props.pic[2].alt}
            src={props.pic[2].path}
          ></img>
        }
      </div>

      <div className="project-description">{props.description}</div>
      <div className="project-link">
        See the source code{" "}
        <a className="project-link-anchor" href={props.link} target="blank">
          here ----&gt;
        </a>
      </div>
    </div>
  );
}

export default Project;
