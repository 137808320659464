import "./Home.css";

function Home() {
  return (
    <section className="home-body">
      <p>Hi, I'm</p>
      <h1 className="my-name">
        <span className="my-first-name">Lukasz</span> Milcz
      </h1>

      <p>
        I am a <br />
        <div className="my-role">
          Front End Engineer / <br></br> Full Stack Developer
        </div>
        <br /> based in Basildon, UK
      </p>
      <p className="my-description">
        If you are looking for a reliable developer whether it's a one-time job
        or a full-time employment contact me via email{" "}
        <span className="email">lukasz@milcz.co.uk</span>
      </p>
    </section>
  );
}

export default Home;
