import './App.css';
import { HashRouter, Routes, Route } from "react-router-dom";
import Media from 'react-media';
import Nav from './components/Nav'
import Home from './pages/Home'
import About from './pages/About'
import Skills from './pages/Skills'
import Work from './pages/Work'
import Contact from './pages/Contact'

function App() {
  return (
    <div className="app-body">
      <Media query="(max-width: 922px)" render={() =>
          (
            <>
              <Home />
              <About />
              <Skills />
              <Work />
              <Contact />
            </>
          )}
        />
        <Media query="(min-width: 923px)" render={() =>
          (
            <HashRouter>
              <Nav />
              <Routes>
                <Route path="/" element={<Home />}></Route>
                <Route path="/about" element={<About />}></Route>
                <Route path="/skills" element={<Skills />}></Route>
                <Route path="/work" element={<Work />}></Route>
              </Routes>
            </HashRouter>
          )}
        />
      
    </div>
  );
}

export default App;
