import "./Work.css";
import { useState, useEffect } from "react";
import Project from "../components/Project";
import projects from "../data/projects.json";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

function Work() {
  const [currentProject, setCurrentProject] = useState(0);
  const [fade, setFade] = useState("");

  useEffect(() => {
    setFade("fade-in");
  }, []);

  const nextProject = () => {
    setFade("fade-out");
    setTimeout(() => {
      if (projects.length >= currentProject + 2) {
        setCurrentProject((currentProject) => currentProject + 1);
        setFade("fade-in");
      } else {
        setCurrentProject(0);
        setFade("fade-in");
      }
    }, 500);
  };

  const prevProject = () => {
    setFade("fade-out");
    setTimeout(() => {
      if (currentProject > 0) {
        setCurrentProject((currentProject) => currentProject - 1);
        setFade("fade-in");
      } else {
        setCurrentProject(projects.length - 1);
        setFade("fade-in");
      }
    }, 500);
  };

  return (
    <section className="work-body">
      <h2 className="work-header">My Projects</h2>

      <div className={`work-project-container ${fade}`}>
        <Project
          key={projects[currentProject].id}
          pic={projects[currentProject].pic}
          title={projects[currentProject].title}
          description={projects[currentProject].description}
          link={projects[currentProject].link}
        />
        <div className="arrows-container">
          <BsChevronLeft onClick={prevProject} className="arrows arrow-left" />
          <BsChevronRight
            onClick={nextProject}
            className="arrows arrow-right"
          />
        </div>
      </div>
    </section>
  );
}

export default Work;
